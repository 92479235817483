import { render, staticRenderFns } from "./todo.vue?vue&type=template&id=6c061ded&"
import script from "./todo.vue?vue&type=script&lang=js&"
export * from "./todo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports